import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const BioStyles = styled.div`
  display: flex;
  margin-bottom: var(--spacing-16);

  p {
    margin-bottom: var(--spacing-0);
  }

  .bio-avatar {
    margin-right: var(--spacing-4);
    margin-bottom: var(--spacing-0);
    min-width: 50px;
    border-radius: 100%;
  }
`;

export default function Bio() {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <BioStyles>
      <Img
        fixed={data.avatar.childImageSharp.fixed}
        alt="Brian Broom"
        className="bio-avatar"
      />

      <p>
        Written by <strong>Brian Broom</strong> who is learning how to study the
        Bible more effectively by understanding the culture and setting of its
        writing.
      </p>
    </BioStyles>
  );
}
